.addeditblog {
  background: $white-color;
  box-shadow: 8px 8px 8px 8px #eaeaea;
  padding: 1rem;
  border-radius: 0.5rem;
  &-blogtext1 {
    font-size: 1.2rem;
    color: $primary-color;
    line-height: 3rem;
    font-weight: 600;
  }
  &-blogtext2 {
    font-size: 1rem;
    color: $black-color;
    /* line-height: 2rem; */
    font-weight: 400;
  }
  &-blogform {
    padding: 1rem;
    display: flex;
    gap: 2.5rem;
    &--left {
      flex: 1;
    }
    &--right {
      flex: 1;
    }
  }
}
