.layout {
  width: 100%;
  display: flex;

  &.collapsed {
    .layout-sidebar {
      width: 6rem;
    }

    .navigation-link span {
      display: none;
    }

    .navigation-link {
      justify-content: center;
    }

    .sidebarheader-sidebarheaderimage {
      width: 2.6rem;
      height: 3rem;

      img {
        animation: rotationAnimation 0.5s linear forwards;
      }
    }

    .sidebarheader h2 {
      display: none;
    }
  }

  &-sidebar {
    width: 20%;
    background: $bg-color;
    height: 100vh;
    // overflow: hidden;
    overflow-y: auto;
    transition: width 0.3s ease;
    position: sticky;
    left: 0;
    top: 0;
    padding: 0.5rem;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  }

  &-topheadercontent {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--topheader {
      background: $bg-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: $border-radius;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
      height: auto;
      padding: 0.5rem 1rem;
      margin: 1rem;
    }

    &--content {
      padding: 0rem 1rem;
      width: 100%;
    }
  }
}
