.listpage {
  background: $white-color;
  box-shadow: 2px 2px 6px rgba(33, 33, 33, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  gap: 0.5rem;
  padding: 1rem;
  &-top {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & h1 {
      font-size: 1.5rem;
      font-weight: 800;
      color: $black-color;
      line-height: normal;
    }
    &--textlink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & h2 {
        font-size: 1rem;
        font-weight: 400;
        color: rgba($color: $black-color, $alpha: 0.5);
      }
      &_addsetting {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        // &__add {
        //   font-size: 0.8rem;
        //   font-weight: 400;
        //   color: $white-color;
        //   background: $bg-color;
        //   border: 1px solid $bg-color;
        //   padding: 0.5rem 0.8rem;
        //   border-radius: $border-radius;
        //   &:hover {
        //     background: $white-color;
        //     color: $bg-color;
        //     transition: 0.5s all linear;
        //   }
        // }
        &__setting {
          border-radius: $border-radius;
          // padding: 0.6rem 0.8rem;
          width: 2.2rem;
          height: 2.2rem;
          background: $bg-color;
          color: $white-color;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8rem;
          cursor: pointer;
        }
      }
    }
  }
  &-searchentry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--search {
      position: relative;
      width: 300px;
      & input[type="search"] {
        width: 100%;
        display: inline-block;
        background: $white-color;
        border: 0.1px solid $bg-color;
        border-radius: $border-radius;
        height: 2.5rem;
        font-size: 1rem;
        padding: 1rem;
      }
      & i {
        position: absolute;
        right: 0%;
        top: 0%;
        height: 100%;
        border-radius: $border-radius;
        padding: 0.6rem 0.8rem;
        background: $bg-color;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
      }
    }
    &--pageselect {
      width: 50px;
      display: inline-block;
      background: $white-color;
      border: 0.1px solid $bg-color;
      border-radius: $border-radius;
      height: 2.5rem;
      font-size: 1rem;
      color: $black-color;
      // padding: 1rem;
    }
    &--selects {
      display: flex;
      gap: 1rem;
      &_filterselect {
        width: 100px;
        display: inline-block;
        background: $white-color;
        border: 0.1px solid $bg-color;
        border-radius: $border-radius;
        height: 2.5rem;
        font-size: 1rem;
        // padding: 1rem;
      }
      &_pageselect {
        width: 50px;
        display: inline-block;
        background: $white-color;
        border: 0.1px solid $bg-color;
        border-radius: $border-radius;
        height: 2.5rem;
        font-size: 1rem;
        // padding: 1rem;
      }
    }
  }
  &-tablesection {
    & table {
      width: 100%;
      border-collapse: collapse;
      overflow-x: scroll;
      border-radius: $border-radius;
      & th {
        background: $bg-color;
        color: $white-color;
        padding: 0.5rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
      & td {
        padding: 0.5rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
      & tbody {
        & tr:nth-child(even) {
          background-color: #f2f2f2;
        }
        & tr:hover {
          background-color: #ddd;
        }
      }
    }
    & .status {
      border-radius: $border-radius;
      background: rgba($color: $black-color, $alpha: 0.5);
      border: none;
      padding: 0.3rem 0.9rem;
      color: $white-color;
      font-size: 0.7rem;
      font-weight: 400;
    }
    & .notfound {
      font-size: 1rem;
      font-weight: 400;
    }
    & .tableimage {
      width: 100px;
      height: 80px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & .actionlinks {
      display: flex;
      // justify-content: center;
      align-items: center;
      gap: 0.5rem;
      &-view {
        border-radius: $border-radius;
        // padding: 0.6rem 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
        background: $yellow-color;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        cursor: pointer;
      }
      &-ride {
        border-radius: $border-radius;
        padding: 0rem 0.8rem;
        width: auto;
        height: 2.2rem;
        background: rgba($color: $black-color, $alpha: 0.5);
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        cursor: pointer;
      }
      &-edit {
        border-radius: $border-radius;
        // padding: 0.6rem 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
        background: $bg-color;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        cursor: pointer;
      }
      &-delete {
        border-radius: $border-radius;
        // padding: 0.6rem 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
        background: $red-color;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        cursor: pointer;
      }
      &-password {
        border-radius: $border-radius;
        // padding: 0.6rem 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
        background: $key-color;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        cursor: pointer;
      }
      &-images {
        border-radius: $border-radius;
        // padding: 0.6rem 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
        background: #0a6616;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        cursor: pointer;
      }
      &-pdf {
        border-radius: $border-radius;
        // padding: 0.6rem 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
        background: #860b03;
        color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        cursor: pointer;
      }
    }
    &--pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
      & button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        margin: 0 5px;
        // padding: 0.6rem 0.8rem;
        width: 2.2rem;
        height: 2.2rem;
        border: none;
        background-color: $bg-color;
        color: $white-color;
        border: 1px solid $bg-color;
        cursor: pointer;
        border-radius: $border-radius;
        &:hover {
          background-color: $white-color;
          color: $bg-color;
        }
      }
      & span {
        margin: 0 5px;
      }
    }
  }
}
