.loader {
  background: #00000080;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999 !important;
  display: none;
  justify-content: center;
  align-items: center;
  &.active {
    display: flex;
  }
  &-element {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid $bg-color;
    border-top-color: $primary-color;
    animation: rotate 1s infinite;
  }
}

@keyframes rotate {
  100% {
    rotate: 360deg;
  }
}
