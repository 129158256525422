.side {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sidebarheader {
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  &-sidebarheaderimage {
    width: 100%;
    height: 4.5rem;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & h2 {
    font-family: heyOctober;
    color: $primary-color;
    font-size: 1.5rem;
    letter-spacing: 3px;
  }
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-link {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white-color;
    border-radius: $border-radius;
    &:hover,
    &.active {
      color: $white-color;
      background: $primary-color;
      transition: all 0.3s linear;
      & span {
        color: $white-color;
      }
      & .chevron {
        color: $white-color;
      }
      & .icon {
        background-color: $white-color;
        color: $primary-color;
      }
    }
    &--items {
      padding: 0.5rem;
      color: $black-color;
      font-size: 0.9rem;
      text-decoration: none;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 1rem;
      text-transform: capitalize;
      & .icon {
        align-items: center;
        border-radius: $border-radius;
        color: rgba(68, 68, 68, 0.533);
        display: flex;
        font-size: 1.1rem;
        height: 2rem;
        justify-content: center;
        width: 2rem;
        background: $primary-color;
        color: $white-color;
      }
    }
    & .chevron {
      font-size: 0.8rem;
      color: $primary-color;
      padding-right: 0.5rem;
    }
  }
}
