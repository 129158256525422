.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  z-index: 999;
  background-color: $white-color;
  border-radius: $border-radius !important;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  outline: none;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white-color;
    border-radius: $border-radius $border-radius 0 0;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgb(156, 153, 153);
    & h2 {
      font-size: 1.2rem;
      font-weight: 600;
      color: $primary-color;
      text-transform: uppercase;
    }
    i {
      border-radius: $border-radius;
      // padding: 0.6rem 0.8rem;
      width: 2.2rem;
      height: 2.2rem;
      background: $primary-color;
      color: $white-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }
  &-body {
    max-height: 80vh;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 1rem;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $bg-color;
      // border-radius: $border-radius !important;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-color;
      // border-radius: $border-radius !important;
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 1rem;
    border-top: 1px solid rgb(156, 153, 153);
  }
}

.modal-open {
  overflow: hidden;
}
.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.575);
}

.confirmdiv {
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  & i {
    font-size: 4rem;
    color: gray;
    font-weight: 300;
  }
  & p {
    font-size: 1rem;
    font-weight: 500;
    color: $primary-color;
    text-align: center;
    line-height: 1.5rem;
  }
  &-btns {
    display: flex;
    gap: 1rem;
  }
}

.modalcontent {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  & p {
    font-size: 1rem;
    font-weight: 500;
    color: $black-color;
    line-height: 1.5rem;
  }
  & .blogimage {
    width: 100%;
    height: 300px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    position: relative;
    margin-bottom: 1.5rem;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    & .blogtitle {
      position: absolute;
      right: 5%;
      bottom: 5%;
      font-size: 1.2rem;
      font-weight: 600;
      color: $primary-color;
      background: $bg-color;
      padding: 8px 24px;
    }
  }
}

.quilldesc {
  & h2,
  h1 {
    font-size: 1.1rem;
  }
  & p {
    font-size: 0.9rem;
  }
}

.coursecontent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  background: $bg-color;
  border-radius: 8px;
  padding: 1.5rem;
  &-name {
    color: $white-color;
    font-size: 1.2rem;
    font-weight: 500;
  }
  & hr {
    height: 2px;
    color: $white-color;
    width: 100%;
  }
  &-price {
    color: $primary-color;
    font-size: 1.5rem;
    font-weight: 700;
  }
  &-desc {
    color: $white-color;
    font-size: 1rem;
    font-weight: 500;
  }
  & ul {
    list-style-type: none;
    color: $white-color;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.9rem;
    & li {
      & i {
        color: $primary-color;
      }
    }
  }
}

.videodetail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &-thumbnail {
    width: 100%;
    height: 300px;
    & img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &-top {
    display: flex;
    align-items: center;
    gap: 1rem;
    &--image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 4px solid $primary-color;
      & img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
    &--content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      & h2 {
        font-size: 1.3rem;
        color: $primary-color;
      }
      & h3 {
        font-size: 1rem;
      }
    }
  }
  &-about {
    font-size: 1.1rem;
    color: $primary-color;
    font-weight: 600;
  }
}

.userdetail {
  display: flex;
  gap: 1rem;
  &-left {
    flex: 1;
    & p {
      font-size: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  &-right {
    flex: 1;
    &--image {
      height: 200px;
      width: 100%;
      & img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.imagedetail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & table {
    width: 100%;
    & th {
      background: $bg-color;
      color: $white-color;
      font-size: 1rem;
    }
  }
}
