$bg-color: #151f32;
$primary-color: #ff4500;
$secondary-color: #68bbe3;
$content-width: 2560px;
$red-color: #ed1b24;
$black-color: #1b1b1b;
$white-color: #ffffff;
$yellow-color: #df871b;
$green-color: #044b37;
$border-radius: 4px;
$key-color: #080d1aa4;
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
}
body {
  background: $white-color;
  max-width: $content-width;
  a {
    text-decoration: none;
  }
  margin-left: auto;
  margin-right: auto;
  textarea:focus,
  input:focus,
  select:focus {
    outline: none;
  }
  & img {
    object-fit: cover;
  }
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $bg-color;
  /* border-radius: 8px; */
}
::-webkit-scrollbar-thumb {
  background: $primary-color;
  /* border-radius: 8px; */
}
@keyframes rotationAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ql-container.ql-snow {
  height: 22rem !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: $black-color;
  padding: 0.75rem;
  border-radius: 50%;
  border: none;
  color: $white-color !important;
}

.primary {
  &-button {
    font-size: 0.9rem;
    font-weight: 400;
    color: $white-color;
    background: $primary-color;
    border: 1px solid $primary-color;
    padding: 8px 24px;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: $white-color;
      color: $primary-color;
      transition: 0.5s all linear;
    }
  }
}

.action {
  &-button {
    font-size: 0.9rem;
    font-weight: 400;
    color: $white-color;
    background: $green-color;
    border: 1px solid $green-color;
    padding: 8px 24px;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.secondary {
  &-button {
    font-size: 0.9rem;
    font-weight: 400;
    color: $white-color;
    background: $bg-color;
    border: 1px solid $bg-color;
    padding: 8px 24px;
    border-radius: $border-radius;
    cursor: pointer;
    &:hover {
      background: $white-color;
      color: $bg-color;
      transition: 0.5s all linear;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
label {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3rem;
  color: $black-color;
}

label::after {
  content: attr(data-after);
  color: red;
}

input[type="password"],
input[type="text"],
input[type="email"],
input[type="number"],
input[type="file"],
textarea,
.customselect {
  width: 100%;
  display: inline-block;
  background: $white-color;
  border: 0.5px solid #ccc;
  border-radius: $border-radius;
  height: max-content;
  font-size: 1rem;
  margin: 0.5rem 0rem;
  padding: 1rem;
  resize: none;
  color: $black-color;
  &:focus {
    border: 0.5px solid $primary-color;
  }
}

input[type="radio"] {
  accent-color: $primary-color;
}
.passwordbutton {
  position: relative;
  &-button {
    position: absolute;
    right: 3%;
    top: 40%;
    height: max-content;
    overflow: hidden;
    background: none;
    border: none;
    cursor: pointer;
    & i {
      font-size: 1.1rem;
      color: $black-color;
      font-weight: 300 !important;
    }
  }
}

.submitbtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0.5rem;
}

.formselect {
  width: 100%;
  display: inline-block;
  background: $white-color;
  border: 0.5px solid $black-color;
  border-radius: $border-radius;
  height: max-content;
  font-size: 1rem;
  margin: 0.5rem 0rem;
  padding: 1rem;
  &:focus {
    border: 0.5px solid $primary-color;
  }
}

.toggle {
  border: 1px solid rgb(178, 202, 223);
  height: 20px;
  width: 40px;
  border-radius: 10px;
  background-color: gray;
  position: relative;
  transition: all 200ms;
}
.toggleactive {
  border: 1px solid rgb(178, 202, 223);
  height: 20px;
  width: 40px;
  border-radius: 10px;
  background-color: $red-color;
  position: relative;
  transition: all 200ms;
}

.switch {
  height: 18px;
  width: 17px;
  // background-color: rgb(178, 202, 223);
  background-color: $white-color;
  border-radius: 999px;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: ease-in 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchslide {
  transition: ease-in 200ms;
  position: absolute;
  left: 60%;
  top: 0px;
  height: 18px;
  width: 17px;
  background-color: $white-color;
  border-radius: 999px;
  position: absolute;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th {
  padding: 0.5rem 0.2rem;
  font-size: 1rem;
  border: 1px solid #d3d3d3;
}

table td {
  border: 1px solid #d3d3d3;
  padding: 0.5rem 0.2rem;
  font-size: 0.9rem;
}

.descform {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}

.radioinputdiv {
  margin: 0.7rem 0rem;
  display: flex;
  gap: 1rem;
  &-radioinput {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}
.statusdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  &-status {
    border-radius: 8px;
    background: rgba($color: $black-color, $alpha: 0.5);
    border: none;
    padding: 0.3rem 0.9rem;
    color: $white-color;
    font-size: 0.7rem;
    font-weight: 400;
  }
}
