@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@font-face {
  font-family: heyOctober;
  src: url("../font/HeyOctober.ttf");
  line-height: 140%;
}
@import "default";
@import "login";
@import "sidebar";
@import "topheader";
@import "layout";
@import "../../components/loader/css/style";
@import "./listpage";
@import "./modal";
@import "../../pages/dashboard/css/dashboard";
@import "../../pages/blog/css/blogform";
