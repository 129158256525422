.loginform {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 90%;
  background-color: $white-color;
  border-radius: $border-radius;
  &-loginformtop {
    background: $bg-color;
    padding: 1rem;
    border-radius: $border-radius $border-radius 0 0;
    &--loginformtoptext {
      position: relative;
      padding-bottom: 3rem;
      & h1 {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 2rem;
        color: $white-color;
        text-align: center;
        text-shadow: 4px 4px 4px $black-color;
        letter-spacing: 6px;
        text-transform: uppercase;
      }
      & h2 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 3rem;
        color: $white-color;
        text-align: center;
        // text-shadow: 1px 1px 4px $black-color;
        letter-spacing: 2px;
      }
    }
    &--loginformtopimage {
      position: absolute;
      height: 6rem;
      width: 6rem;
      padding: 0.5rem;
      left: 40%;
      border-radius: $border-radius;
      top: 22%;
      background-color: $white-color;
      box-shadow: 1px 1px 3px #eaeaea;
      border: 2px solid $bg-color;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &--loginformtopimagelogo {
      position: absolute;

      padding: 0.5rem;
      left: 0;
      right: 0;
      border-radius: $border-radius;
      top: 18%;

      display: flex;

      justify-content: center;
      align-items: center;
      &_text {
        background-color: $white-color;
        box-shadow: 1px 1px 3px #eaeaea;
        border: 2px solid $bg-color;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 8rem;
        width: 8rem;
        & h1 {
          // padding-top: 1rem;
          font-size: 1.5rem;
          // font-family: heyOctober;
          line-height: 2rem;
          color: $bg-color;
          text-transform: uppercase;
          font-weight: 900;
          // text-shadow: 4px 4px 4px $black-color;
          letter-spacing: 6px;
        }
        & h2 {
          font-size: 1.1rem;
          // font-family: heyOctober;
          line-height: 2rem;
          color: $bg-color;
          text-transform: uppercase;
          font-weight: 800;
        }
      }
    }
  }

  &-loginformform {
    padding: 5rem 2rem 1rem 2rem;
    background: $white-color;
    border: 2px solid $bg-color;
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
  }
}
