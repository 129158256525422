.hamburger {
  & i {
    font-size: 1.5rem;
    cursor: pointer;
    color: $primary-color;
  }
}
.adminicons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &-password {
    align-items: center;
    border-radius: $border-radius;
    background-color: $white-color;
    color: $primary-color;
    display: flex;
    font-size: 0.8rem;
    // padding: 0.6rem 0.8rem;
    width: 2.2rem;
    height: 2.2rem;
    justify-content: center;
    cursor: pointer;
  }
  &-iconname {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    position: relative;
    & i {
      align-items: center;
      border-radius: $border-radius;
      background-color: $primary-color;
      color: $white-color;
      display: flex;
      font-size: 0.8rem;
      // padding: 0.6rem 0.8rem;
      width: 2.2rem;
      height: 2.2rem;
      justify-content: center;
    }
    & h2 {
      color: $primary-color;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 400;
    }
    & .logoutdiv {
      position: absolute;
      top: 130%;
      left: 0%;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      width: 115px;
      cursor: pointer;
      background-color: #fff;
      border-radius: $border-radius;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
      padding: 0.3rem;
      & i {
        align-items: center;
        background-color: $white-color;
        color: $red-color;
        display: flex;
        font-size: 0.8rem;
        justify-content: center;
        // padding: 0.6rem 0.8rem;
      }
      & span {
        color: $red-color;
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: 400;
      }
    }
  }
}
