.dashboard {
  //   background: $white-color;
  //   box-shadow: 2px 2px 6px rgba(33, 33, 33, 0.1);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  &-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & h2 {
      font-size: 1rem;
      font-weight: 400;
      color: rgba($color: $black-color, $alpha: 0.5);
    }
    & h1 {
      font-size: 1.5rem;
      font-weight: 800;
      color: $black-color;
      line-height: normal;
    }
  }
  &-analytics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    &--card {
      margin-top: 1rem;
      background: $white-color;
      border-radius: 1rem;
      box-shadow: 2px 2px 6px rgba(33, 33, 33, 0.1);
      padding: 1rem;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      border: 1px solid rgba($color: $black-color, $alpha: 0.2);
      &_icon {
        position: absolute;
        top: -25%;
        left: 10%;
        background: $bg-color;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        width: 4rem;
        border-radius: 1rem;
        & i {
          color: $white-color;
          font-size: 1.5rem;
        }
      }
      & h2 {
        font-size: 1.3rem;
        font-weight: 500;
        color: $black-color;
        line-height: 3rem;
      }
      & h1 {
        font-size: 3rem;
        font-weight: 600;
        color: $primary-color;
        line-height: 3rem;
      }
    }
  }
}
